export default class Util {
    /**
     * 校验对象中是否有空值
     * @param {*} obj 
     */
    checkObj(obj) {
        for (let key in obj) {
            if (!obj[key]) {
                return false;
            }
        }
        return true
    }
    /**
     * 正则校验姓名
     * @param {*} name 
     */
    checkName(name) {
        var reg = /^([\u4e00-\u9fa5]){2,7}$/
        if (!name) { //输入不能为空
            return false;
        } else if (!reg.test(name)) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 正则校验手机号
     * @param {*} phone 
     */
    checkPhone(phone) {
        var reg = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (phone == "") { //输入不能为空
            return false;
        } else if (!reg.test(phone)) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 正则校验邮箱
     * @param {*} value 
     */
    checkMail(value) {
        var reg = new RegExp("^([0-9A-Za-z\\-_\\.]+)@([0-9a-z]+\\.[a-z]{2,3}(\\.[a-z]{2})?)$"); //正则表达式
        if (value == "") { //输入不能为空
            return false;
        } else if (!reg.test(value)) { //正则验证不通过，格式不对
            return false;
        } else {
            return true;
        }
    }
}
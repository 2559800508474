import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index')
    },
    {
        path: '/Scheme',
        name: 'Scheme',
        component: () => import('@/views/Scheme')
    },
    {
        path: '/Applications',
        name: 'Applications',
        component: () => import('@/views/Applications')
    }, {
        path: '/Product',
        name: 'Product',
        component: () => import('@/views/Product')
    },{
        path: '/Informations',
        name: 'Informations',
        component: () => import('@/views/Informations')
    },{
        path: '/ClassicCase',
        name: 'ClassicCase',
        component: () => import('@/views/ClassicCase')
    },
    {
        path: '/Server',
        name: 'Server',
        component: () => import('@/views/Server')
    },{
        path: '/Contact',
        name: 'Contact',
        component: () => import('@/views/Contact')
    }, {
        path: '/NewsDetail',
        name: 'NewsDetail',
        component: () => import('@/views/NewsDetail')
    }, {
        path: '/About',
        name: 'About',
        component: () => import('@/views/About')
    }, {
        path: '/HosContact',
        name: 'HosContact',
        component: () => import('@/views/HosContact')
    }, {
        path: '*',
        name: 'NoFound',
        component: () => import('@/views/NoFound')
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})
router.beforeEach((to, from, next) => {

    const { path, query } = to
    if (!query.timestamp) {
    Object.assign(query, {
      'timestamp': new Date().getTime()
    })
      next({
        path,
        query
      })
    } else {
    next()
    }
})
export default router

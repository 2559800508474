/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */

import axios from "axios"
//测试地址
// let baseUrl = "http://192.168.3.25:8003"
// 创建移动端用户统一授权登录的axios 实例

let MainAxios = axios.create({
    baseURL: '/api'
});

let headersConfig = {
    //   'Content-type': 'application/x-www-form-urlencoded'
}

// 响应拦截器
MainAxios.interceptors.response.use(
    response => {
        if (response.data.code != "200") {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
    },
    // 服务器状态码不是200的情况
    error => {
        // loadingserve.endLoading();
        try {
            if (error.response.status) {
                switch (error.response.status) {
                    //case 400:  未登录
                    // 未登录则跳转登录页面，并携带当前页面的路径
                    // 在登录成功后返回当前页面，这一步需要在登录页操作。
                    case 401:
                        break;
                        // 403 token过期
                        // 登录过期对用户进行提示
                        // 清除本地token和清空vuex中token对象
                        // 跳转登录页面
                    case 403:
                        break;
                        // 404请求不存在
                    case 404:
                        break;
                        // 其他错误，直接抛出错误提示
                    default:
                }
                return Promise.reject(error.response);
            }
        } catch (e) {}
    }
);

/* 主要业务服务，MainAxios，端口号8802*/
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 **/
export function Get(url, params) {
    if (!params) {
        params = ""
    }
    return new Promise(function (resolve, reject) {
        MainAxios.get(url, {
                headers: headersConfig,
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Post(url, params) {
    return new Promise((resolve, reject) => {
        if (!params) {
            params = {}
        }
        MainAxios.post(url, params, {
                headers: headersConfig
            }).then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err)
            })
    });
}

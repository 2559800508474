import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

import { Api } from '@/api/api'
// animated 动画库
import animated from 'animate.css'
// 挂载全局工具类
import Util from './common/util'
import '@/static/css/common.css'

Vue.use(animated)
Vue.prototype.$Util = Util


Vue.use(VueAwesomeSwiper)
Vue.use(Element, { locale })

Vue.prototype.$Api = Api
require('@/static/css/common.css')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

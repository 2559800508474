import { Get, Post } from './http'

let getTopList = function (params) {
    return Get('/news/top', params)
}
let selectlist = function (params) {
    return Get('/news/list', params)
}
let selectNewsContent = function (params) {
    return Get('/news/content/' + params)
}
let addMsg = function (params) {
    return Post('/customer/add/', params)
}
let postFn = function (params) {
    return Post('url', params)
}
// 评价
let evaluationList = function (params) {
    return Get('/evaluation/list', params)
}
// 招聘
let employList = function (params) {
    return Get('/employ/list', params)
}
// 相关推荐
let relevantList = function (params) {
    return Get('/news/relevant', params)
}
// 医疗人才招聘
let hospitalRecruitmentList = function (params) {
    return Get('/hospital/recruitment/list', params)
}
export const Api = {
    getTopList: getTopList,
    selectlist: selectlist,
    selectNewsContent: selectNewsContent,
    addMsg: addMsg,
    postFn: postFn,
    employList: employList,
    relevantList: relevantList,
    evaluationList: evaluationList,
    hospitalRecruitmentList: hospitalRecruitmentList
}
